import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import _ from 'lodash';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import { notification } from 'antd';
import { PHONE_NUMBER } from '@vl/mod-utils/validateUtil';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const account_id = ctx.apply('accountModel.getAccountId');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const form = useFormik({
                initialValues: {
                  account_id,
                  first_name: '',
                  last_name: '',
                  avatar_url: '',
                  date_of_birth: '',
                  place_of_birth: '',
                  email: '',
                  phone: '',
                  note: '',
                  start_at: '',
                  roles: [
                    {
                      id: 'student',
                    },
                  ],
                },
                validateOnChange: true,
                validateOnBlur: false,
                onSubmit: async (values, actions) => {
                  try {
                    const payload = {
                      account_id,
                      first_name: values.first_name,
                      last_name: values.last_name,
                      date_of_birth: values.date_of_birth,
                      place_of_birth: values.place_of_birth,
                      email: values.email,
                      phone: values.phone,
                      avatar_url: _.get(values, 'avatar_url.0.url', ''),
                      note: values.note,
                      roles: values.roles,
                      created_by: user_id,
                    };
                    const res = await fbFnsClient.getClient().post('triggers-tool-createMember', { ...payload });
                    if (!!_.get(res, 'status')) {
                      await form.resetForm();
                      ctx.apply('paginationModel.onRefresh');
                      notification.success({
                        message: ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.Notification.add_success'),
                      });
                      ctx.apply('modalModel.hide');
                    }

                    if (_.has(res, 'errors')) {
                      notification.error({
                        message: ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.Notification.add_error'),
                      });
                    }
                  } catch (err) {
                    console.log(err);
                    notification.error({
                      message: ctx.apply('i18n.t', 'ZoomToolMember.Add_Form.Notification.add_error'),
                    });
                  }
                },
                validationSchema: Yup.object().shape({
                  first_name: Yup.string().required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                  last_name: Yup.string().required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                  email: Yup.string()
                    .email()
                    .required(ctx.apply('i18n.t', 'JobPost.Validation.required')),
                  phone: Yup.string().matches(PHONE_NUMBER, ctx.apply('i18n.t', 'JobPost.Validation.phone')),
                }),
              });

              _.assign(form, {
                isModalVisible,
                canSubmit: !form.isSubmitting && form.dirty && form.isValid,
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
